import { template as template_c3f297a4e22641ac914d99b42c28203b } from "@ember/template-compiler";
const FKText = template_c3f297a4e22641ac914d99b42c28203b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
