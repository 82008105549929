import { template as template_09b29c22f7114810ab4325ed65f858c0 } from "@ember/template-compiler";
const WelcomeHeader = template_09b29c22f7114810ab4325ed65f858c0(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
