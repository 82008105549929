import { template as template_4269b7884d6f479a8e934d448f2a341b } from "@ember/template-compiler";
const FKLabel = template_4269b7884d6f479a8e934d448f2a341b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
